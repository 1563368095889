<template>
  <div class="col-12 c">
    <div class="card card-body">
        <div class="col-12">
            <button class="btn btn-primary" v-b-modal.new>
                <i class="fa fa-plus"></i>
                اضافة خطة جديدة
            </button>
            <br><br>
        </div>
        <app-collapse>
            <app-collapse-item v-for="plan in plans" :key="plan._id" class="itemm" :title="'🗓️ ' + plan.title + ' ('+plan.classname+')'">
               <div class="col-12">
                 <div class="row">
                    <button class="btn btn-sm btn-success" @click="current = plan; subject_name = '';" v-b-modal.add style="border-radius: 0px">
                        <i class="fa fa-plus"></i>
                        إضافة مادة
                    </button>
                    <button class="btn btn-sm btn-warning" @click="current = plan; print()" style="border-radius: 0px">
                        <i class="fa fa-print"></i>
                        طباعة
                    </button>
                    <div class="form-check">
                        <label class="form-check-label">
                            &nbsp;
                            <input type="checkbox" class="form-check-input" v-model="plan.public" value="yes">
                            نشر لأولياء الامور
                        </label>
                    </div>
                </div>
               </div>
                <div class="table-responsive" :id="'p_' + plan._id">
                    <table class="table table-sm table-bordered table-hover bg-white" style="margin-top: 5px">
                        <thead>
                            <th>
                                المادة
                            </th>
                            <th></th>
                            <th>
                                الأحد
                                <br>
                                <span class="dnone">{{ plan.days['1'] }}</span>
                                <input class="form-control hidemex" style="border-radius: 0px; text-align: center;" placeholder="اكتب التاريخ..." v-model="plan.days['1']">
                            </th>
                            <th>
                                الاثنين
                                <br>
                                <span class="dnone">{{ plan.days['2'] }}</span>
                                <input class="form-control hidemex" style="border-radius: 0px; text-align: center;" placeholder="اكتب التاريخ..." v-model="plan.days['2']">
                            </th>
                            <th>
                                الثلاثاء
                                <br>
                                <span class="dnone">{{ plan.days['3'] }}</span>
                                <input class="form-control hidemex" style="border-radius: 0px; text-align: center;" placeholder="اكتب التاريخ..." v-model="plan.days['3']">
                            </th>
                            <th>
                                الاربعاء
                                <br>
                                <span class="dnone">{{ plan.days['4'] }}</span>
                                <input class="form-control hidemex" style="border-radius: 0px; text-align: center;" placeholder="اكتب التاريخ..." v-model="plan.days['4']">
                            </th>
                            <th>
                                الخميس 
                                <br>
                                <span class="dnone">{{ plan.days['5'] }}</span>
                                <input class="form-control hidemex" style="border-radius: 0px; text-align: center;" placeholder="اكتب التاريخ..." v-model="plan.days['5']">
                            </th>
                        </thead>
                        <tbody>
                            <template v-for="(subject, i) in plan.subjects">
                                <tr :key="subject.name">
                                    <td rowspan="3" class="text-center"><br>
                                        {{ subject.name }}
                                        <br>
                                        <a href="javascript:;" class="text-danger hidemex" @click="current = plan; deleteSubject(plan, i)">
                                            <i class="fa fa-trash"></i>
                                            ازالة
                                        </a>
                                    </td>
                                    <td style="text-align: center">الدرس</td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[1].title }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[1].title"></textarea>
                                    </td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[2].title }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[2].title"></textarea>
                                    </td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[3].title }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[3].title"></textarea>
                                    </td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[4].title }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[4].title"></textarea>
                                    </td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[5].title }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[5].title"></textarea>
                                    </td>
                                </tr>
                                <tr :key="subject.name + '1'">
                                    <td style="text-align: center">الاهداف</td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[1].target }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[1].target"></textarea>
                                    </td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[2].target }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[2].target"></textarea>
                                    </td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[3].target }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[3].target"></textarea>
                                    </td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[4].target }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[4].target"></textarea>
                                    </td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[5].target }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[5].target"></textarea>
                                    </td>
                                </tr>
                                <tr :key="subject.name + '2'">
                                    <td style="text-align: center">الواجبات</td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[1].homework }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[1].homework"></textarea>
                                    </td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[2].homework }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[2].homework"></textarea>
                                    </td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[3].homework }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[3].homework"></textarea>
                                    </td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[4].homework }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[4].homework"></textarea>
                                    </td>
                                    <td style="padding: 0px">
                                        <span class="dnone">{{ subject.days[5].homework }}</span>
                                        <textarea class="form-control hidemex" style="border-radius: 0px" v-model="subject.days[5].homework"></textarea>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <small class="text-danger">
                    <a href="javascript:;" class="text-danger" @click="deletePlan(plan._id)">
                        <i class="fa fa-trash"></i>
                        حذف الخطة
                    </a>
                </small>
            </app-collapse-item>
        </app-collapse>
    </div>
    <b-modal id="new" title="اضافة خطة جديدة" hide-footer>
        <div class="form-group">
          <label for="">العنوان</label>
          <input type="text"
            class="form-control" v-model="title" placeholder="عنوان الخطة هنا...">
        </div>
        <div class="form-group">
          <label for="">الصف</label>
          <select class="form-control" v-model="classname">
            <option :value="''">-- اختر --</option>
            <option v-for="c in classes" :key="c" :value="c">
                {{ c }}
            </option>
          </select>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="addNow()">
                اضافة الخطة <i class="fa fa-arrow-left"></i>
            </button>
        </div>
    </b-modal>
    <b-modal id="add" title="اضافة مادة" hide-footer>
        <div class="form-group">
          <label for="">اسم المادة</label>
          <input type="text"
            class="form-control" v-model="subject_name" placeholder="اسم المادة هنا...">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="addSubjectNow()">
                اضافة الآن
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal, BFormCheckbox, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
    components: {
        BFormCheckbox,
        BModal,
        AppCollapse,
        AppCollapseItem,
        BDropdown,
        BDropdownItem,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            teacher: JSON.parse(localStorage.getItem('teacher')),
            plans: [],
            title: "",
            classname: "",
            classes: [],
            current: {},
            dones: [],
            subject_name: "",
            interval: null
        }
    },
    created(){
        var g = this;
        g.getPlans()
    },
    beforeDestroy(){
        var g = this;
        if(g.interval){
            clearInterval(g.interval)
        }
    },
    methods: {
        deleteSubject(plan, i){
            var g = this;
            var s = [];
            plan.subjects.forEach(function(a, i2){
                if(i != i2){
                    s.push(a)
                }
            })
            g.current.subjects = s
        },
        checkInterval(){
            var g = this;
            if(!g.interval){
                var original = JSON.stringify(g.plans)
                g.interval = setInterval(function(){
                    if(original != JSON.stringify(g.plans)){
                        original = JSON.stringify(g.plans)
                        $.post(api + '/teacher/weeklyplan/save', {
                            username: g.teacher.username,
                            password: g.teacher.password,
                            plans: original
                        }).then(function(r){
                            g.loading = false
                            if(r.status != 100){
                                alert(r.response, 200)
                            }else{
                            }
                        }).fail(function(){
                        })
                    }
                }, 1000)
            }
        },
        getPlans(){
            var g = this;
            $.post(api + '/teacher/weeklyplan/plans', {
                username: this.teacher.username,
                password: this.teacher.password
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.plans = r.response;
                    g.checkInterval()
                    $.post(api + '/teacher/classes', {
                        username: g.teacher.username,
                        password: g.teacher.password
                    }).then(function(r){
                        g.loading = false
                        if(r.status != 100){
                            alert(r.response)
                        }else{
                            g.classes = r.response;
                            var x = [];
                            g.classes.forEach(function(a){
                                if(!x.includes(a.classname) && !g.plans.map(function(d){return d.classname}).includes(a.classname)){
                                    x.push(a.classname)
                                }
                            })
                            g.classes = x;
                        }
                    }).fail(function(){
                    })
                }
            }).fail(function(){
            })
        },
        addNow(){
            var g = this;
            $.post(api + '/teacher/weeklyplan/create', {
                username: this.teacher.username,
                password: this.teacher.password,
                title: this.title,
                classname: this.classname
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    $("#new___BV_modal_header_ > button").click()
                    g.getPlans()
                }
            }).fail(function(){
            })
        },
        deletePlan(id){
            if(confirm("متأكد من الحذف؟")){
                var g = this;
                $.post(api + '/teacher/weeklyplan/delete', {
                    username: this.teacher.username,
                    password: this.teacher.password,
                    id: id
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response, 200)
                    }else{
                        g.getPlans()
                    }
                }).fail(function(){
                })
            }
        },
        addSubjectNow(){
            var g = this;
            g.current.subjects.push({
                name: g.subject_name,
                days: {
                    1: {
                        title: "",
                        target: "",
                        homework: ""
                    },
                    2: {
                        title: "",
                        target: "",
                        homework: ""
                    },
                    3: {
                        title: "",
                        target: "",
                        homework: ""
                    },
                    4: {
                        title: "",
                        target: "",
                        homework: ""
                    },
                    5: {
                        title: "",
                        target: "",
                        homework: ""
                    },
                }
            })
            $("#add___BV_modal_header_ > button").click()
        },
        print(){
            var g = this;
            var divToPrint=document.getElementById("p_" + g.current._id);
            var newWin= window.open("");
            newWin.document.write(`
            <html dir='rtl'>
                <head>
                    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css">
                    <title>التحضير الذكي - tahdir.net</title>
                    <style>
                        table, td, th {
                            border: 1px solid #86AB89 !important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        table, table *{
                            white-space: normal;
                        }
                        .hidemex{
                            display:none;
                        }
                        th{
                            background: #E7FBE6 !important
                        }
                    </style>
                </head>
                <body>
                    <div class='container-fluid'>
                        <div class='row'>
                            <div class='col-4 text-center'>
                                <h5>
                                <br><br>
                                    المملكة العربية السعودية
                                    <br>
                                    ${g.teacher.schoolname}
                                </h5>
                            </div>
                            <div class='col-4 text-center'><img style='width: 200px' src="https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg"></div>
                            <div class='col-4 text-center'>
                            <h5>
                                <br><br>
                                    خطة التعلم الأسبوعي
                                    <br>
                                    الصف: ${g.current.classname}
                                </h5>
                            </div>
                        </div>
                        ${divToPrint.outerHTML?.replace("table-hover", "")}
                    </div>
                </body>
            </html>
            `);
            setTimeout(() => {
                newWin.document.close()
                newWin.print();
                newWin.close();
            }, 1000);
        }
    }
}
</script>


<style>
.itemm{
    background: #fafafa; border: solid 1px #eee;
    margin-top: 5px
}
.itemm [aria-expanded="false"]:hover{
    background: #eee !important;
}
table, table *{
    white-space: nowrap;
}
table textarea{
    min-width: 200px;
}
</style>